<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" :title="formTitle">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="工号" prop="jobNumber" >
        <a-input v-model="form.jobNumber" placeholder="请输入工号" />
      </a-form-model-item>
      <a-form-model-item label="姓名" prop="name" >
        <a-input v-model="form.name" placeholder="请输入姓名" />
      </a-form-model-item>
      <a-form-model-item label="身份证号" prop="identityCard" >
        <a-input v-model="form.identityCard" placeholder="请输入身份证号" />
      </a-form-model-item>
      <a-form-model-item label="银行卡号" prop="bankCardNumber" >
        <a-input v-model="form.bankCardNumber" placeholder="请输入银行卡号" />
      </a-form-model-item>
      <a-form-model-item label="基本工资" prop="basePay" >
        <a-input v-model="form.basePay" placeholder="请输入基本工资" />
      </a-form-model-item>
      <a-form-model-item label="加班费" prop="overtimePay" >
        <a-input v-model="form.overtimePay" placeholder="请输入加班费" />
      </a-form-model-item>
      <a-form-model-item label="岗位工资" prop="salaryPost" >
        <a-input v-model="form.salaryPost" placeholder="请输入岗位工资" />
      </a-form-model-item>
      <a-form-model-item label="早餐补助" prop="breakfastAllowance" >
        <a-input v-model="form.breakfastAllowance" placeholder="请输入早餐补助" />
      </a-form-model-item>
      <a-form-model-item label="安全奖" prop="safetyAward" >
        <a-input v-model="form.safetyAward" placeholder="请输入安全奖" />
      </a-form-model-item>
      <a-form-model-item label="年节奖金" prop="yearFestivalBonus" >
        <a-input v-model="form.yearFestivalBonus" placeholder="请输入年节奖金" />
      </a-form-model-item>
      <a-form-model-item label="高温补贴" prop="highTemperatureSubsidy" >
        <a-input v-model="form.highTemperatureSubsidy" placeholder="请输入高温补贴" />
      </a-form-model-item>
      <a-form-model-item label="激励奖励" prop="incentiveReward" >
        <a-input v-model="form.incentiveReward" placeholder="请输入激励奖励" />
      </a-form-model-item>
      <a-form-model-item label="其他福利" prop="welfareOther" >
        <a-input v-model="form.welfareOther" placeholder="请输入其他福利" />
      </a-form-model-item>
      <a-form-model-item label="病假扣款" prop="sickLeaveDeduction" >
        <a-input v-model="form.sickLeaveDeduction" placeholder="请输入病假扣款" />
      </a-form-model-item>
      <a-form-model-item label="事假扣款" prop="personalLeaveDeduction" >
        <a-input v-model="form.personalLeaveDeduction" placeholder="请输入事假扣款" />
      </a-form-model-item>
      <a-form-model-item label="旷工扣款" prop="absenteeismDeduction" >
        <a-input v-model="form.absenteeismDeduction" placeholder="请输入旷工扣款" />
      </a-form-model-item>
      <a-form-model-item label="未满勤扣款" prop="notFullAttendanceDeduction" >
        <a-input v-model="form.notFullAttendanceDeduction" placeholder="请输入未满勤扣款" />
      </a-form-model-item>
      <a-form-model-item label="餐补扣款" prop="mealAllowanceDeduction" >
        <a-input v-model="form.mealAllowanceDeduction" placeholder="请输入餐补扣款" />
      </a-form-model-item>
      <a-form-model-item label="罚款" prop="fine" >
        <a-input v-model="form.fine" placeholder="请输入罚款" />
      </a-form-model-item>
      <a-form-model-item label="预发工资" prop="advanceSalary" >
        <a-input v-model="form.advanceSalary" placeholder="请输入预发工资" />
      </a-form-model-item>
      <a-form-model-item label="补发工资" prop="salaryBack" >
        <a-input v-model="form.salaryBack" placeholder="请输入补发工资" />
      </a-form-model-item>
      <a-form-model-item label="养老(企业)" prop="endowmentInsuranceEnterprise" >
        <a-input v-model="form.endowmentInsuranceEnterprise" placeholder="请输入养老(企业)" />
      </a-form-model-item>
      <a-form-model-item label="医疗(企业)" prop="medicalInsuranceEnterprise" >
        <a-input v-model="form.medicalInsuranceEnterprise" placeholder="请输入医疗(企业)" />
      </a-form-model-item>
      <a-form-model-item label="生育(企业)" prop="maternityInsuranceEnterprise" >
        <a-input v-model="form.maternityInsuranceEnterprise" placeholder="请输入生育(企业)" />
      </a-form-model-item>
      <a-form-model-item label="工伤(企业)" prop="employmentInsuranceEnterprise" >
        <a-input v-model="form.employmentInsuranceEnterprise" placeholder="请输入工伤(企业)" />
      </a-form-model-item>
      <a-form-model-item label="失业(企业)" prop="unemploymentInsuranceEnterprise" >
        <a-input v-model="form.unemploymentInsuranceEnterprise" placeholder="请输入失业(企业)" />
      </a-form-model-item>
      <a-form-model-item label="公积金(企业)" prop="housingProvidentFundEnterprise" >
        <a-input v-model="form.housingProvidentFundEnterprise" placeholder="请输入公积金(企业)" />
      </a-form-model-item>
      <a-form-model-item label="养老(个人)" prop="pensionInsurancePersonal" >
        <a-input v-model="form.pensionInsurancePersonal" placeholder="请输入养老(个人)" />
      </a-form-model-item>
      <a-form-model-item label="医疗(个人)" prop="medicalInsurancePersonal" >
        <a-input v-model="form.medicalInsurancePersonal" placeholder="请输入医疗(个人)" />
      </a-form-model-item>
      <a-form-model-item label="失业(个人)" prop="unemploymentInsurancePersonal" >
        <a-input v-model="form.unemploymentInsurancePersonal" placeholder="请输入失业(个人)" />
      </a-form-model-item>
      <a-form-model-item label="公积金(个人)" prop="housingProvidentFundPersonal" >
        <a-input v-model="form.housingProvidentFundPersonal" placeholder="请输入公积金(个人)" />
      </a-form-model-item>
      <a-form-model-item label="个税(个人)" prop="incomeTaxPersonal" >
        <a-input v-model="form.incomeTaxPersonal" placeholder="请输入个税(个人)" />
      </a-form-model-item>
      <a-form-model-item label="应发合计" prop="totalPayable" >
        <a-input v-model="form.totalPayable" placeholder="请输入应发合计" />
      </a-form-model-item>
      <a-form-model-item label="实发工资" prop="netPayment" >
        <a-input v-model="form.netPayment" placeholder="请输入实发工资" />
      </a-form-model-item>
      <a-form-model-item label="病假(日)" prop="sickLeave" >
        <a-input v-model="form.sickLeave" placeholder="请输入病假(日)" />
      </a-form-model-item>
      <a-form-model-item label="事假(日)" prop="personalLeave" >
        <a-input v-model="form.personalLeave" placeholder="请输入事假(日)" />
      </a-form-model-item>
      <a-form-model-item label="旷工(日)" prop="absenteeism" >
        <a-input v-model="form.absenteeism" placeholder="请输入旷工(日)" />
      </a-form-model-item>
      <a-form-model-item label="未满勤(日)" prop="notFullAttendance" >
        <a-input v-model="form.notFullAttendance" placeholder="请输入未满勤(日)" />
      </a-form-model-item>
      <a-form-model-item label="工资月份" prop="salaryDate" v-if="this.formType === 1">
        <a-date-picker style="width: 100%" v-model="form.salaryDate" value-format="YYYY-MM-DD HH:mm:ss" format="YYYY-MM" allow-clear/>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getSalaryRecord, addSalaryRecord, updateSalaryRecord } from '@/api/iot/salaryRecord'

export default {
  name: 'CreateForm',
  props: {
    sexOptions: {
      type: Array,
      required: true
    },
    statusOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        jobNumber: null,
        name: null,
        identityCard: null,
        bankCardNumber: null,
        basePay: null,
        overtimePay: null,
        salaryPost: null,
        breakfastAllowance: null,
        safetyAward: null,
        yearFestivalBonus: null,
        highTemperatureSubsidy: null,
        incentiveReward: null,
        welfareOther: null,
        sickLeaveDeduction: null,
        personalLeaveDeduction: null,
        absenteeismDeduction: null,
        notFullAttendanceDeduction: null,
        mealAllowanceDeduction: null,
        fine: null,
        advanceSalary: null,
        salaryBack: null,
        endowmentInsuranceEnterprise: null,
        medicalInsuranceEnterprise: null,
        maternityInsuranceEnterprise: null,
        employmentInsuranceEnterprise: null,
        unemploymentInsuranceEnterprise: null,
        housingProvidentFundEnterprise: null,
        pensionInsurancePersonal: null,
        medicalInsurancePersonal: null,
        unemploymentInsurancePersonal: null,
        housingProvidentFundPersonal: null,
        incomeTaxPersonal: null,
        totalPayable: null,
        netPayment: null,
        sickLeave: null,
        personalLeave: null,
        absenteeism: null,
        notFullAttendance: null,
        salaryDate: null,
        status: 0
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        jobNumber: [
          { required: true, message: '工号不能为空', trigger: 'blur' }
        ],
        identityCard: [
          { required: true, message: '身份证不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '姓名不能为空', trigger: 'blur' }
        ],
        sex: [
          { required: true, message: '性别不能为空', trigger: 'blur' }
        ],
        salaryDate: [
          { required: true, message: '工资月份不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        jobNumber: null,
        name: null,
        identityCard: null,
        bankCardNumber: null,
        basePay: null,
        overtimePay: null,
        salaryPost: null,
        breakfastAllowance: null,
        safetyAward: null,
        yearFestivalBonus: null,
        highTemperatureSubsidy: null,
        incentiveReward: null,
        welfareOther: null,
        sickLeaveDeduction: null,
        personalLeaveDeduction: null,
        absenteeismDeduction: null,
        notFullAttendanceDeduction: null,
        mealAllowanceDeduction: null,
        fine: null,
        advanceSalary: null,
        salaryBack: null,
        endowmentInsuranceEnterprise: null,
        medicalInsuranceEnterprise: null,
        maternityInsuranceEnterprise: null,
        employmentInsuranceEnterprise: null,
        unemploymentInsuranceEnterprise: null,
        housingProvidentFundEnterprise: null,
        pensionInsurancePersonal: null,
        medicalInsurancePersonal: null,
        unemploymentInsurancePersonal: null,
        housingProvidentFundPersonal: null,
        incomeTaxPersonal: null,
        totalPayable: null,
        netPayment: null,
        sickLeave: null,
        personalLeave: null,
        absenteeism: null,
        notFullAttendance: null,
        salaryDate: null,
        status: 0
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getSalaryRecord(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateSalaryRecord(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          } else {
            addSalaryRecord(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
