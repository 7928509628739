var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.formTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "工号", prop: "jobNumber" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入工号" },
                model: {
                  value: _vm.form.jobNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "jobNumber", $$v)
                  },
                  expression: "form.jobNumber",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "姓名", prop: "name" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入姓名" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "身份证号", prop: "identityCard" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入身份证号" },
                model: {
                  value: _vm.form.identityCard,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "identityCard", $$v)
                  },
                  expression: "form.identityCard",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "银行卡号", prop: "bankCardNumber" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入银行卡号" },
                model: {
                  value: _vm.form.bankCardNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "bankCardNumber", $$v)
                  },
                  expression: "form.bankCardNumber",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "基本工资", prop: "basePay" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入基本工资" },
                model: {
                  value: _vm.form.basePay,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "basePay", $$v)
                  },
                  expression: "form.basePay",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "加班费", prop: "overtimePay" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入加班费" },
                model: {
                  value: _vm.form.overtimePay,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "overtimePay", $$v)
                  },
                  expression: "form.overtimePay",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "岗位工资", prop: "salaryPost" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入岗位工资" },
                model: {
                  value: _vm.form.salaryPost,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "salaryPost", $$v)
                  },
                  expression: "form.salaryPost",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "早餐补助", prop: "breakfastAllowance" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入早餐补助" },
                model: {
                  value: _vm.form.breakfastAllowance,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "breakfastAllowance", $$v)
                  },
                  expression: "form.breakfastAllowance",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "安全奖", prop: "safetyAward" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入安全奖" },
                model: {
                  value: _vm.form.safetyAward,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "safetyAward", $$v)
                  },
                  expression: "form.safetyAward",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "年节奖金", prop: "yearFestivalBonus" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入年节奖金" },
                model: {
                  value: _vm.form.yearFestivalBonus,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "yearFestivalBonus", $$v)
                  },
                  expression: "form.yearFestivalBonus",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "高温补贴", prop: "highTemperatureSubsidy" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入高温补贴" },
                model: {
                  value: _vm.form.highTemperatureSubsidy,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "highTemperatureSubsidy", $$v)
                  },
                  expression: "form.highTemperatureSubsidy",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "激励奖励", prop: "incentiveReward" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入激励奖励" },
                model: {
                  value: _vm.form.incentiveReward,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "incentiveReward", $$v)
                  },
                  expression: "form.incentiveReward",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "其他福利", prop: "welfareOther" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入其他福利" },
                model: {
                  value: _vm.form.welfareOther,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "welfareOther", $$v)
                  },
                  expression: "form.welfareOther",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "病假扣款", prop: "sickLeaveDeduction" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入病假扣款" },
                model: {
                  value: _vm.form.sickLeaveDeduction,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "sickLeaveDeduction", $$v)
                  },
                  expression: "form.sickLeaveDeduction",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "事假扣款", prop: "personalLeaveDeduction" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入事假扣款" },
                model: {
                  value: _vm.form.personalLeaveDeduction,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "personalLeaveDeduction", $$v)
                  },
                  expression: "form.personalLeaveDeduction",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "旷工扣款", prop: "absenteeismDeduction" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入旷工扣款" },
                model: {
                  value: _vm.form.absenteeismDeduction,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "absenteeismDeduction", $$v)
                  },
                  expression: "form.absenteeismDeduction",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "未满勤扣款",
                prop: "notFullAttendanceDeduction",
              },
            },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入未满勤扣款" },
                model: {
                  value: _vm.form.notFullAttendanceDeduction,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "notFullAttendanceDeduction", $$v)
                  },
                  expression: "form.notFullAttendanceDeduction",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "餐补扣款", prop: "mealAllowanceDeduction" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入餐补扣款" },
                model: {
                  value: _vm.form.mealAllowanceDeduction,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "mealAllowanceDeduction", $$v)
                  },
                  expression: "form.mealAllowanceDeduction",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "罚款", prop: "fine" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入罚款" },
                model: {
                  value: _vm.form.fine,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "fine", $$v)
                  },
                  expression: "form.fine",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "预发工资", prop: "advanceSalary" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入预发工资" },
                model: {
                  value: _vm.form.advanceSalary,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "advanceSalary", $$v)
                  },
                  expression: "form.advanceSalary",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "补发工资", prop: "salaryBack" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入补发工资" },
                model: {
                  value: _vm.form.salaryBack,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "salaryBack", $$v)
                  },
                  expression: "form.salaryBack",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "养老(企业)",
                prop: "endowmentInsuranceEnterprise",
              },
            },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入养老(企业)" },
                model: {
                  value: _vm.form.endowmentInsuranceEnterprise,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "endowmentInsuranceEnterprise", $$v)
                  },
                  expression: "form.endowmentInsuranceEnterprise",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "医疗(企业)",
                prop: "medicalInsuranceEnterprise",
              },
            },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入医疗(企业)" },
                model: {
                  value: _vm.form.medicalInsuranceEnterprise,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "medicalInsuranceEnterprise", $$v)
                  },
                  expression: "form.medicalInsuranceEnterprise",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "生育(企业)",
                prop: "maternityInsuranceEnterprise",
              },
            },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入生育(企业)" },
                model: {
                  value: _vm.form.maternityInsuranceEnterprise,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "maternityInsuranceEnterprise", $$v)
                  },
                  expression: "form.maternityInsuranceEnterprise",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "工伤(企业)",
                prop: "employmentInsuranceEnterprise",
              },
            },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入工伤(企业)" },
                model: {
                  value: _vm.form.employmentInsuranceEnterprise,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "employmentInsuranceEnterprise", $$v)
                  },
                  expression: "form.employmentInsuranceEnterprise",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "失业(企业)",
                prop: "unemploymentInsuranceEnterprise",
              },
            },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入失业(企业)" },
                model: {
                  value: _vm.form.unemploymentInsuranceEnterprise,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "unemploymentInsuranceEnterprise", $$v)
                  },
                  expression: "form.unemploymentInsuranceEnterprise",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "公积金(企业)",
                prop: "housingProvidentFundEnterprise",
              },
            },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入公积金(企业)" },
                model: {
                  value: _vm.form.housingProvidentFundEnterprise,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "housingProvidentFundEnterprise", $$v)
                  },
                  expression: "form.housingProvidentFundEnterprise",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: { label: "养老(个人)", prop: "pensionInsurancePersonal" },
            },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入养老(个人)" },
                model: {
                  value: _vm.form.pensionInsurancePersonal,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "pensionInsurancePersonal", $$v)
                  },
                  expression: "form.pensionInsurancePersonal",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: { label: "医疗(个人)", prop: "medicalInsurancePersonal" },
            },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入医疗(个人)" },
                model: {
                  value: _vm.form.medicalInsurancePersonal,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "medicalInsurancePersonal", $$v)
                  },
                  expression: "form.medicalInsurancePersonal",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "失业(个人)",
                prop: "unemploymentInsurancePersonal",
              },
            },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入失业(个人)" },
                model: {
                  value: _vm.form.unemploymentInsurancePersonal,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "unemploymentInsurancePersonal", $$v)
                  },
                  expression: "form.unemploymentInsurancePersonal",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "公积金(个人)",
                prop: "housingProvidentFundPersonal",
              },
            },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入公积金(个人)" },
                model: {
                  value: _vm.form.housingProvidentFundPersonal,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "housingProvidentFundPersonal", $$v)
                  },
                  expression: "form.housingProvidentFundPersonal",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "个税(个人)", prop: "incomeTaxPersonal" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入个税(个人)" },
                model: {
                  value: _vm.form.incomeTaxPersonal,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "incomeTaxPersonal", $$v)
                  },
                  expression: "form.incomeTaxPersonal",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "应发合计", prop: "totalPayable" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入应发合计" },
                model: {
                  value: _vm.form.totalPayable,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "totalPayable", $$v)
                  },
                  expression: "form.totalPayable",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "实发工资", prop: "netPayment" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入实发工资" },
                model: {
                  value: _vm.form.netPayment,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "netPayment", $$v)
                  },
                  expression: "form.netPayment",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "病假(日)", prop: "sickLeave" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入病假(日)" },
                model: {
                  value: _vm.form.sickLeave,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "sickLeave", $$v)
                  },
                  expression: "form.sickLeave",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "事假(日)", prop: "personalLeave" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入事假(日)" },
                model: {
                  value: _vm.form.personalLeave,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "personalLeave", $$v)
                  },
                  expression: "form.personalLeave",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "旷工(日)", prop: "absenteeism" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入旷工(日)" },
                model: {
                  value: _vm.form.absenteeism,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "absenteeism", $$v)
                  },
                  expression: "form.absenteeism",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "未满勤(日)", prop: "notFullAttendance" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入未满勤(日)" },
                model: {
                  value: _vm.form.notFullAttendance,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "notFullAttendance", $$v)
                  },
                  expression: "form.notFullAttendance",
                },
              }),
            ],
            1
          ),
          this.formType === 1
            ? _c(
                "a-form-model-item",
                { attrs: { label: "工资月份", prop: "salaryDate" } },
                [
                  _c("a-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      "value-format": "YYYY-MM-DD HH:mm:ss",
                      format: "YYYY-MM",
                      "allow-clear": "",
                    },
                    model: {
                      value: _vm.form.salaryDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "salaryDate", $$v)
                      },
                      expression: "form.salaryDate",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }